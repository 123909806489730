import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import RenderProduct from "./RenderProduct";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Prices from "components/Prices";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { useCartDataProvider } from "../../contaxt/CartProductContext";
import { Controller, useForm, useFormContext } from "react-hook-form"; // Assuming you're using react-hook-form
import DeliveryAddressForm from "./DeliveryAddress";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Label from "components/Label/Label";
import TimePicker from "components/TimePicker";
import { getMenuScheduleData } from "services/HomeService";

interface OrderSummaryProps {
  products: any[];
  reOrderData: any;
  setHistoryOrders: Dispatch<SetStateAction<undefined>>;
  setsubTotal: Dispatch<SetStateAction<number>>;
  searchProduct: string;
  calculatingTotalPrice: (item: any) => number;
  merchantname: string;
  toggleAccordion: () => void;
  isAccordionOpen: boolean;
  selectTime: boolean;
  // pickupTime: string;
  // handlePickupTimeChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  // handleAsapChange: () => void;
  timeOptions: string[] | null | undefined;
  isAsap: boolean;
  handleTipSelection: (tip: string) => void;
  selectedTip: string;
  handleDeliveryTipSelection: (tip: string) => void;
  selectedDeliveryTip: string;
  customTip: any;
  handleCustomTipChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errorMessage: string;
  carterror: string;
  NoteContent: () => React.ReactNode;
  allProductPrice: number;
  taxDisplayList: string[];
  taxDisplayTotal: number[];
  finalTip: (selectedTip: string) => number;
  finalDeliveryTip: (selectedDeliveryTip: string) => number;
  storedOrderFee: number;
  storedOrderFeeLabel: string;
  optionDelivery: (value: any) => void;
  saveDelivery: (value: any) => void;
  quoteId: string;
  quoteProvider: string;
  deliveryFee: string;
  estimatedTime: number;
  pickUp: number;
  totAmt: number;
  isPaymentProcessing: boolean;
  confirmOrder: boolean;
  isQuotesProcessing: boolean;
  deliveryAddress: any;
  pickUpTime: (dateTime: string) => void;
  noQuotes: boolean;
}

const OrderSummary: React.FC<OrderSummaryProps> = ({
  products,
  reOrderData,
  setHistoryOrders,
  setsubTotal,
  searchProduct,
  calculatingTotalPrice,
  merchantname,
  toggleAccordion,
  isAccordionOpen,
  selectTime,
  
  // handlePickupTimeChange,
  // handleAsapChange,
  timeOptions,
  isAsap,
  handleTipSelection,
  selectedTip,
  handleDeliveryTipSelection,
  selectedDeliveryTip,
  customTip,
  handleCustomTipChange,
  errorMessage,
  carterror,
  NoteContent,
  allProductPrice,
  taxDisplayList,
  taxDisplayTotal,
  finalTip,
  finalDeliveryTip,
  optionDelivery,
  saveDelivery,
  storedOrderFee,
  storedOrderFeeLabel,
  totAmt,
  quoteId,
  quoteProvider,
  deliveryFee,
  estimatedTime,
  pickUp,
  isPaymentProcessing, 
  confirmOrder,
  isQuotesProcessing,
  deliveryAddress,
  pickUpTime,
  noQuotes
}) => {
  const [items, setItems] = useState(reOrderData?.orderItems || null);
  console.log(items, reOrderData, "itemssummary");
  const navigate = useNavigate();
  const { dispatch }: any = useCartDataProvider();
  const [option, setOption] = useState("pickup");
  const [deliveryOn, setDeliveryOn] = useState(false);
  const [address, setAddress] = useState(""); 
  const [data,setdata]=useState("");
  const isDelivery = localStorage.getItem("isDelivery");
  const [quote_id, setQuoteId] = useState(quoteId || null);
  const[isFormValue , setIsFormValue]=useState(false)
  const [newDeliveryFee, setNewDeliveryFee] = useState((Number(deliveryFee) / 100) || "0");
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedTime, setSelectedTime] = useState<string>(""); 
  const [pickUpTimes, setPickUpTimes] = useState<string>("");
  const [checkPickUp,setCheckPickUp]=useState(false)
  // const leadpickup = localStorage.getItem("pickUpTime");
  const [newquote,setNewQuote] = useState(noQuotes || false);
  
  const [manuScheludeData, setManuScheludeData] = useState<any[]>([]);
  const [errorMessages, setErrorMessages] = useState("");
   const [schedulePickup, setSchedulePickup] = useState(false);
    // const leadpickupDate = leadpickup && (new Date(leadpickup));
    // const currentpickuptime = leadpickupDate && leadpickupDate.toLocaleTimeString();
  const fromTime = localStorage.getItem("fromTime") || "00:00";
  const toTime = localStorage.getItem("toTime") || "23:59";
  const checkTime = localStorage.getItem("checkTime");
   const [showTooltip, setShowTooltip] = useState(false);

   const handleConfirmClick = () => {
     if (deliveryOn) {
       setShowTooltip(true);
       console.log("showTooltip", showTooltip);
       setTimeout(() => setShowTooltip(false), 7000); // Hide tooltip after 3 seconds
     } else {
       // Proceed with the confirm order logic
     }
   };
  // console.log(checkTime, leadpickup, "checkingpickuptime");
  const orderLeadTime =
    localStorage.getItem("onlineOrderSettingsLeadTime") || 0;
  console.log(
    "fromTime",
    parseInt(fromTime) + parseInt(orderLeadTime.toString()),
    localStorage.getItem("fromTime"),
    "toTime",
    toTime
  );
  const handleIncrement = (index: number) => {
    console.log("enter123");
    const newItems = [...items];
    newItems[index].quantity += 1;
    setItems(newItems);
    calculateSubtotal(newItems);
  };
  useEffect(() => {
    const getMenuSchedule = async () => {
      try {
        const response: any = await getMenuScheduleData();
        setManuScheludeData(response.data);
      } catch (error) {
        console.log("error", error);
      }
    };
    getMenuSchedule();
  }, []);
  console.log(checkTime, "deliveryquote");
   const {
     control,
     setValue,
     clearErrors,
     
     formState: { errors },
   } = useFormContext();
//  const {
//    control,
//    handleSubmit,
//    formState: { errors },
//    setValue,
//    clearErrors,
//    reset,
//  } = useForm({
//    defaultValues: {
    
     
//      address1: "",
//      address2: "",
//      city: "",
//      state: "",
//      country: "USA ",
//      zip: "",
//    },
  //  });
  useEffect(() => {
    setQuoteId(quoteId);
  },[quoteId])
  console.log(deliveryAddress, "deliveryAddress");
  const handleDecrement = (index: number) => {
    const newItems = [...items];
    if (newItems[index].quantity > 1) {
      newItems[index].quantity -= 1;
      setItems(newItems);
      calculateSubtotal(newItems);
    }
  };
  // useEffect(() => {
  //   if (leadpickup) {
  //     const [leadPickupHour, leadPickupMinute] = leadpickup?.split(":");
  //     const leadPickupDate = new Date();
  //     leadPickupDate.setHours(
  //       Number(leadPickupHour),
  //       Number(leadPickupMinute),
  //       0,
  //       0
  //     );
  //     const currentTime = new Date();

  //     if (currentTime >= leadPickupDate) {
  //       setCheckPickUp(true);
  //       console.log(
  //         "Current time is greater than or equal to lead pickup time."
  //       );
  //     } else {
  //       setCheckPickUp(false);
  //       console.log("Current time is less than lead pickup time.");
  //     }
  //   }
  // },[leadpickup])

  const calculateSubtotal = (items: any[]) => {
    let subtotal = allProductPrice;
    items.forEach((item) => {
      subtotal +=
        item.item.onlinePrice * item.quantity +
        (item.orderItemModifiers?.reduce(
          (acc: any, modifier: any) =>
            acc + modifier.modifierItem.onlinePrice * item.quantity,
          0
        ) || 0);
    });
    return subtotal;
  };
useEffect(() => {
  setNewDeliveryFee(Number(deliveryFee) / 100);
},[deliveryFee])
  const subtotal = reOrderData ? calculateSubtotal(items) : allProductPrice;
  console.log(subtotal, totAmt, allProductPrice, newDeliveryFee, "checktotals");
  const totalAmount = reOrderData
    ? (
        totAmt +Number(newDeliveryFee)+
        subtotal +
       
        reOrderData.tax -
        allProductPrice
      ).toFixed(2)
    : (totAmt+Number(newDeliveryFee) ).toFixed(2);
  useEffect(() => {
    setHistoryOrders(items);
    setsubTotal(subtotal);
  }, [items, subtotal]);
  console.log(taxDisplayList, taxDisplayTotal, "tax2323");
  const handleOptionChange = async(selectedOption: string) => {
    setOption(selectedOption);
    if(selectedOption==="delivery"){
      setDeliveryOn(true);
      setNewDeliveryFee(deliveryFee);
      setSchedulePickup(true);
    }
    else {
      await setNewQuote(false);
      setDeliveryOn(false);
       setSchedulePickup(false);
      setQuoteId(null);
      setNewDeliveryFee("0");
      setDeliveryOn(false);
    }
     optionDelivery(selectedOption);
  };
 
  const handleSave = (data: any) => {
    setdata(data);
    console.log(data, "data345");
   saveDelivery(true)
   setDeliveryOn(false);
 }
console.log(isDelivery,"isdelivery");
console.log(
  control,
  
  errors,
  setValue,
  clearErrors,
  quoteId,
  "checkingall11"
  );
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  console.log("Current Time Zone:", timeZone);
  console.log(selectedDate, selectedTime, "selectedDate");
  useEffect(() => {
    console.log("eneteringuseeffect")
   if (selectedDate !== null) {
     const dateTime = handleDateChange(selectedDate) + " " + selectedTime;
     setPickUpTimes(dateTime);
     console.log(dateTime, "llllll");
     const error = validatePickupTime(selectedTime);
     console.log(error, "errorssss");
     if (option === "pickup") {
       setErrorMessages(error);
     } else if (option === "delivery") {
       setErrorMessages("");
     }
    
   

      pickUpTime(`${dateTime}:00`);
    }
   else {
     const currentDate = new Date();
     const orderLeadTimeMinutes = parseInt(orderLeadTime.toString(), 10); // Convert orderLeadTime to integer

    
     currentDate.setMinutes(currentDate.getMinutes() + orderLeadTimeMinutes);
     const formattedDate = formatDate(currentDate); 
     const currentTime = currentDate.toLocaleTimeString([], {
       hour: "2-digit",
       minute: "2-digit",
       hour12: false,
     });

     const dateTime = formattedDate + " " + currentTime;
     setPickUpTimes(dateTime);
     console.log(dateTime, "current date and time");

     const error = validatePickupTime(currentTime);
     console.log(error, "error messages");
     if (option === "pickup") {
        setNewQuote(false);
       setDeliveryOn(false);
       setErrorMessages(error);
       
     }
     else if(option==="delivery"){
      setErrorMessages("");
     }

      pickUpTime(`${dateTime}:00`); 
    //  const utcCurrentTime = currentDate.toISOString(); // Convert current date and time to UTC
    //  pickUpTime(utcCurrentTime);
    }
    
  },[selectedDate, selectedTime,option])
  const changeDeliveryAddress = () => {
   setQuoteId(null);
   setIsFormValue(true)
  }
    const getCurrentTime = () => {
      const now = new Date();
      const hours = String(now.getHours()).padStart(2, "0"); 
      const minutes = String(now.getMinutes()).padStart(2, "0"); 
      return `${hours}:${minutes}`;
    };
   const formatDate = (date: Date) => {
     const year = date.getFullYear();
     const day = String(date.getDate()).padStart(2, "0");
     const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
     return `${year}-${month}-${day}`;
  };
  function convertTo12HourFormat(time24:any) {
    let [hours, minutes] = time24.split(":");
    hours = parseInt(hours, 10);

    const period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert 0 to 12 for midnight and adjust for 12-hour format

    return `${hours}:${minutes} ${period}`;
  }
  const validatePickupTime = (time: any) => {
   console.log("enteringtimes", time);
    const [selectedHour, selectedMinute] = time.split(":").map(Number);
      // const currentpickuptime = getCurrentTime(); 
  //  const [currentHour, currentMinute] = leadpickup!
  //  .split(":")
  //  .map(Number);
   const [fromHour, fromMinute] = fromTime.split(":").map(Number);
    const [toHour, toMinute] = toTime.split(":").map(Number);
    

   const selectedTimeInMinutes = selectedHour * 60 + selectedMinute;
  //  const currentTimeInMinutes = currentHour * 60 + currentMinute;
    const fromTimeInMinutes = fromHour * 60 + fromMinute;
    const leadMinutes =
      fromTimeInMinutes + parseInt(orderLeadTime.toString(), 10);;
      const resultHours = Math.floor(leadMinutes / 60) % 24; // Use modulo 24 to wrap around if > 24 hours
      const resultMinutes = leadMinutes % 60;

      // Format the result with leading zeros if necessary
      const formattedTime = `${resultHours
        .toString()
        .padStart(2, "0")}:${resultMinutes.toString().padStart(2, "0")}`;
  
    const toTimeInMinutes = toHour * 60 + toMinute;
   if (orderLeadTime !== null && orderLeadTime !== undefined) {
     console.log(toTimeInMinutes - parseInt(orderLeadTime.toString()), toTimeInMinutes, orderLeadTime, "toTimeInMinutes");
   }
console.log(
  "selectedTimeInMinutes",
  selectedTimeInMinutes,
  // currentTimeInMinutes,
  fromTimeInMinutes,
  toTimeInMinutes
);
    // if (selectedTimeInMinutes < currentTimeInMinutes) {
    //   console.log("Pickup unavailable");
    
    //   return "Pickup unavailable during the selected time";
    // }
    //  if (
    //    selectedTimeInMinutes < fromTimeInMinutes ||
    //    selectedTimeInMinutes >
    //      (toTimeInMinutes - parseInt(orderLeadTime.toString()))
    //  ) {
    //    console.log("Pickup unavailable123456");
    //    return "Pickup unavailable: Please select a time between available hours.";
    //  } else {
    //    return "";
    //  }// No error
    if (fromTimeInMinutes > toTimeInMinutes) {
      
      if (
        (selectedTimeInMinutes >= fromTimeInMinutes && selectedTimeInMinutes <= 1440) || // from `fromTime` to midnight
        (selectedTimeInMinutes >= 0 && selectedTimeInMinutes <= toTimeInMinutes) // from midnight to `toTime`
      ) {
        return ""; // No error
      }
    }
      else if (
          selectedTimeInMinutes >= fromTimeInMinutes &&
          selectedTimeInMinutes < fromTimeInMinutes + parseInt(orderLeadTime.toString())
        ) {
          console.log("Pickup unavailable during the selected time");
          return (
            "Pickup available from " +
            convertTo12HourFormat(formattedTime)
            
          );
        }
    
        else if (
          selectedTimeInMinutes <
            fromTimeInMinutes ||
          selectedTimeInMinutes > toTimeInMinutes
        ) {
          console.log("Pickup unavailable123456");
          return "Pickup unavailable: Please select a time between open hours.";
        }
        // No error
      
     
      return ""; // No error
    
  
 };

  const handleTimeChange = (time: any) => {
    setSelectedTime(time);
   
  };
  const pad = (num: number) => (num < 10 ? "0" + num : num);
  const convertToLocalTime = (time: string): string => {

    const [hours, minutes] = time.split(":").map(Number);

    const utcDate = new Date();
    utcDate.setUTCHours(hours, minutes, 0, 0);

    
    const date = new Date(
      utcDate.toLocaleString("en-US", { timeZone: undefined })
    );
  
    const hourss = pad(date.getHours());
    const minutess = pad(date.getMinutes());
    return `${hourss}:${minutess}`;
  };

  const handleDateChange = (date: Date | null) => {
    const dayName = date?.toLocaleDateString("en-US", { weekday: "long" });
    const FindDate = manuScheludeData.find((days) => days.menuDays === dayName);
    const fromTime = convertToLocalTime(FindDate.fromTime).slice(0, 5);
    const toTime = convertToLocalTime(FindDate.toTime).slice(0, 5);
    localStorage.setItem("fromTime", fromTime);
    localStorage.setItem("toTime", toTime);
    setSelectedDate(date);
    if (date) {
      const formattedDate = formatDate(date);
      return formattedDate; 
    }
  };
  console.log(newquote,noQuotes, "errorMessages123");
  return (
    <div className="w-full lg:w-[36%] ">
      <h3 className="text-lg font-semibold">Order Summary</h3>

      <div className="mt-8 divide-y divide-slate-200/70 dark:divide-slate-700 ">
        <>
          {carterror && <p className="text-red-500 mt-2">{carterror}</p>}
          {products
            ?.filter((item: any) =>
              item.name.toLowerCase().includes(searchProduct.toLowerCase())
            )
            .map((item: any, index: any) => (
              <RenderProduct
                key={index}
                item={item}
                index={index}
                calculatingTotalPrice={calculatingTotalPrice}
                merchantname={merchantname ?? `${merchantname}`}
              />
            ))}
        </>
        {reOrderData &&
          reOrderData.orderItems
            ?.filter((item: any) =>
              item.item.name.toLowerCase().includes(searchProduct.toLowerCase())
            )
            .map((item: any, index: any) => {
              let totalPrice =
                item.item.onlinePrice * item.quantity +
                (item.orderItemModifiers?.reduce(
                  (acc: any, modifier: any) =>
                    acc + modifier.modifierItem.onlinePrice * item.quantity,
                  0
                ) || 0);
              return (
                <div
                  key={index}
                  className="relative flex py-4 sm:py-5 xl:py-6 first:pt-0 last:pb-0"
                >
                  <div className="ml-2 sm:ml-3 flex flex-1 flex-col">
                    <div>
                      <div className="flex justify-between ">
                        <div className="flex-[1.5] ">
                          <h3 className="text-base font-semibold">
                            {" "}
                            {item.item.name}
                          </h3>
                          <div className="mt-1.5 sm:mt-2.5 flex text-sm text-slate-600 dark:text-slate-300">
                            <div className="flex items-center space-x-1.5"></div>
                            <span className="mx-4 border-l border-slate-200 dark:border-slate-700 "></span>
                            <div className="flex items-center space-x-1.5"></div>
                          </div>

                          <div className="mt-3 flex justify-between w-full sm:hidden relative">
                            <div className="sm text-center relative">
                              {/* <NcInputNumber
                                className="relative z-40"
                                quantity={item.quantity}
                                id={item.item.id}
                                modifierChecked={item.modifierChecked}
                                item={item.item}
                              /> */}
                              <div className="nc-NcInputNumber flex items-center justify-between space-x-5 relative z-20">
                                <div
                                  className={`nc-NcInputNumber__content flex items-center justify-between w-[104px] sm:w-28`}
                                >
                                  <button
                                    className="w-6 h-6 rounded-full flex items-center justify-center border border-neutral-400 dark:border-neutral-500 bg-white dark:bg-neutral-900 focus:outline-none hover:border-neutral-700 dark:hover:border-neutral-400 disabled:hover:border-neutral-400 dark:disabled:hover:border-neutral-500 disabled:opacity-50 disabled:cursor-default"
                                    type="button"
                                    onClick={() => {
                                      handleDecrement(index);
                                      totalPrice -= item.item.onlinePrice;
                                    }}
                                    disabled={item.quantity <= 1}
                                  >
                                    <MinusIcon className="w-4 h-4" />
                                  </button>
                                  <span className="select-none block flex-1 text-center leading-none">
                                    {item.quantity}
                                  </span>
                                  <button
                                    className="w-6 h-6 rounded-full flex items-center justify-center border border-neutral-400 dark:border-neutral-500 bg-white dark:bg-neutral-900 focus:outline-none hover:border-neutral-700 dark:hover:border-neutral-400 disabled:hover:border-neutral-400 dark:disabled:hover:border-neutral-500 disabled:opacity-50 disabled:cursor-default"
                                    type="button"
                                    onClick={() => {
                                      handleIncrement(index);
                                      totalPrice += item.item.onlinePrice;
                                    }}
                                    disabled={false}
                                  >
                                    <PlusIcon className="w-4 h-4" />
                                  </button>
                                </div>
                              </div>
                            </div>
                            <Prices
                              contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-base font-semibold h-full"
                              onlinePrice={totalPrice}
                            />
                          </div>
                        </div>

                        <div className="hidden sm:block text-center relative">
                          <div className="hidden sm:block text-center relative">
                            <div className="nc-NcInputNumber flex items-center justify-between space-x-5 relative z-20">
                              <div
                                className={`nc-NcInputNumber__content flex items-center justify-between w-[104px] sm:w-28`}
                              >
                                <button
                                  className="w-6 h-6 rounded-full flex items-center justify-center border border-neutral-400 dark:border-neutral-500 bg-white dark:bg-neutral-900 focus:outline-none hover:border-neutral-700 dark:hover:border-neutral-400 disabled:hover:border-neutral-400 dark:disabled:hover:border-neutral-500 disabled:opacity-50 disabled:cursor-default"
                                  type="button"
                                  onClick={() => {
                                    handleDecrement(index);
                                    totalPrice -= item.item.onlinePrice;
                                  }}
                                  disabled={item.quantity <= 1}
                                >
                                  <MinusIcon className="w-4 h-4" />
                                </button>
                                <span className="select-none block flex-1 text-center leading-none">
                                  {item.quantity}
                                </span>
                                <button
                                  className="w-6 h-6 rounded-full flex items-center justify-center border border-neutral-400 dark:border-neutral-500 bg-white dark:bg-neutral-900 focus:outline-none hover:border-neutral-700 dark:hover:border-neutral-400 disabled:hover:border-neutral-400 dark:disabled:hover:border-neutral-500 disabled:opacity-50 disabled:cursor-default"
                                  type="button"
                                  onClick={() => {
                                    handleIncrement(index);
                                    totalPrice += item.item.onlinePrice;
                                  }}
                                  disabled={false}
                                >
                                  <PlusIcon className="w-4 h-4" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="hidden flex-1 sm:flex justify-end text-base font-semibold">
                          <Prices onlinePrice={totalPrice} className="mt-0.5" />
                        </div>
                      </div>
                    </div>

                    {item.orderItemModifiers &&
                      item.orderItemModifiers.length > 0 && (
                        <div className="mt-2">
                          {item.orderItemModifiers.map(
                            (modifier: any, modifierIndex: any) => (
                              <div
                                key={modifierIndex}
                                className="flex flex-col"
                              >
                                <h3 className="text-xs flex justify-between pb-2">
                                  <span>{modifier.modifierItem.name}</span>
                                  <span className="text-slate-900 dark:text-slate-200">
                                    ${modifier.modifierItem.onlinePrice}
                                  </span>
                                </h3>
                              </div>
                            )
                          )}
                        </div>
                      )}
                  </div>
                </div>
              );
            })}
      </div>

      <div className="mt-10">
        {
          <div className="mt-4 text-sm text-slate-500 dark:text-slate-400">
            <div className="flex items-center">
              <button
                type="button"
                onClick={() => handleOptionChange("pickup")}
                className={`flex-1 py-2 rounded-l ${
                  option === "pickup"
                    ? "bg-custom-green  text-white"
                    : "bg-gray-200 text-gray-700"
                }`}
              >
                Pickup
              </button>
              <div className="w-px h-full bg-gray-400"></div>{" "}
              {isDelivery === "true" && (
                <button
                  type="button"
                  onClick={() => handleOptionChange("delivery")}
                  className={`flex-1 py-2 rounded-r ${
                    option === "delivery"
                      ? "bg-custom-green text-white"
                      : "bg-gray-200 text-gray-700"
                  } `}
                  disabled={checkTime === "false"}
                >
                  Delivery
                </button>
              )}
            </div>
            {option === "pickup" && (
              <div className="space-y-4">
                {" "}
                <div className="flex items-center mt-6">
                  <button
                    type="button"
                    onClick={() => setSchedulePickup(false)}
                    disabled={checkTime === "false"}
                    className={`flex-1 py-2 rounded-l ${
                      !schedulePickup && checkTime === "true"
                        ? "bg-custom-green  text-white"
                        : "bg-gray-200 text-gray-700"
                    }`}
                  >
                    Pickup in {orderLeadTime} minutes
                  </button>
                  <div className="w-px h-full bg-gray-400"></div>{" "}
                  <button
                    type="button"
                    onClick={() => setSchedulePickup(true)}
                    className={`flex-1 py-2 rounded-r ${
                      schedulePickup || checkTime === "false"
                        ? "bg-custom-green text-white"
                        : "bg-gray-200 text-gray-700"
                    } `}
                  >
                    Schedule Pickup
                  </button>
                </div>
                {(schedulePickup || checkTime === "false") && (
                  <>
                    <div>
                      <label className="text-sm font-semibold block mb-2 mt-4">
                        Select Date:
                      </label>
                      <DatePicker
                        selected={selectedDate}
                        onChange={(date) => handleDateChange(date)}
                        minDate={new Date()}
                        maxDate={
                          new Date(
                            new Date().setDate(new Date().getDate() + 13)
                          )
                        }
                        className="border border-gray-300 py-2 px-3 rounded-md w-full"
                        placeholderText="Select Date"
                      />
                    </div>
                    <div>
                      <label className="text-sm font-semibold block mb-0">
                        Select Time :
                      </label>

                      <TimePicker
                        name="time"
                        value={selectedTime}
                        onChange={(e: any) => setSelectedTime(e.target.value)}
                        className="border border-gray-300 py-2 px-3 rounded-md w-full"
                      />
                      {errorMessages && (
                        <div className="text-red-500 mt-2">{errorMessages}</div>
                      )}
                    </div>
                  </>
                )}
              </div>
            )}

            {option === "delivery" && !quote_id && (
              <DeliveryAddressForm
                control={control}
                errors={errors}
                setValue={setValue}
                clearErrors={clearErrors}
                namespace="delivery"
                isFormValue={isFormValue}
                // setData={setDeliveryData}
                // quoteId={quoteId}
              />
            )}
            {option === "delivery" && quote_id && (
              <div className="border p-4 rounded-lg shadow-sm mt-4">
                <h1 className="text-xl font-medium text-black ">
                  Delivery Details
                </h1>
                <p>
                  <strong>Deliver To (Name):</strong>{" "}
                  {deliveryAddress.customer_name || "N/A"}
                </p>
                <p>
                  <strong>Contact Number:</strong>{" "}
                  {deliveryAddress.customer_phone || "N/A"}
                </p>
                <p>
                  <strong>Address 1:</strong>{" "}
                  {deliveryAddress.dropoff_street || "N/A"}
                </p>
                <p>
                  <strong>Address 2:</strong>{" "}
                  {deliveryAddress.dropoff_suite || "N/A"}
                </p>
                <p>
                  <strong>City:</strong> {deliveryAddress.dropoff_city || "N/A"}
                </p>
                <p>
                  <strong>State:</strong>{" "}
                  {deliveryAddress.dropoff_state || "N/A"}
                </p>
                <p>
                  <strong>Country:</strong>{" "}
                  {deliveryAddress.dropoff_country || "N/A"}
                </p>
                <p>
                  <strong>Zipcode:</strong>{" "}
                  {deliveryAddress.dropoff_zip || "N/A"}
                </p>
                <p>
                  <strong>Delivery Instructions:</strong>{" "}
                  {deliveryAddress.dropoff_instructions || "N/A"}
                </p>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    changeDeliveryAddress();
                  }}
                  className="block text-green-900 hover:text-green-1000 mt-4 text-center underline"
                >
                  Change Delivery Address
                </a>
              </div>
            )}
          </div>
        }
      </div>
      {option === "delivery" && quote_id && (
        <div className="border p-4 rounded-lg shadow-sm mt-4 text-gray">
          <p className="text-gray-500">
            <strong>Delivery Provider :</strong> {quoteProvider}
          </p>
          <p className="text-gray-500">
            <strong>ETA :</strong> aprx {estimatedTime} minutes
          </p>
          {/* <h2>Delivery Provider : {quoteProvider}</h2>
          <p>Estimated Time : {estimatedTime} minutes</p> */}
        </div>
      )}
      {option === "delivery" && !quote_id && (
        <>
          {noQuotes && (
            <div className="text-red-600 text-sm mb-2">
              Sorry, Delivery address is out of range. Please enter a different
              address or switch to pickup.
            </div>
          )}
          <ButtonPrimary
            className="justify-center mt-8 w-full"
            type="button"
            disabled={isQuotesProcessing}
            onClick={() => handleSave(data)}
          >
            {isQuotesProcessing ? "Loading..." : "Choose Delivery Option"}
          </ButtonPrimary>
        </>
      )}
      {option === "delivery" && quote_id && (
        <ButtonPrimary
          className="justify-center mt-8 w-full "
          type="button"
          disabled={isQuotesProcessing}
          onClick={() => handleSave(data)}
        >
          {isQuotesProcessing ? "Loading..." : " Change Delivery Option"}
        </ButtonPrimary>
      )}

      <div className=" pt-6 text-sm text-slate-500 dark:text-slate-400  border-slate-200/70 dark:border-slate-700 ">
        <div className="mt-1 mb-5">
          {option === "pickup" && (
            <div className="mt-1 mb-5">
              <p className="text-sm font-semibold text-slate-700 dark:text-slate-200">
                Add a Tip
              </p>
              <div className="flex space-x-2 mt-2">
                {["none", "10%", "15%", "20%", "custom"].map((tip) => (
                  <button
                    key={tip}
                    type="button"
                    onClick={() => handleTipSelection(tip)}
                    className={`px-4 py-2 border rounded ${
                      selectedTip === tip
                        ? "bg-black text-white"
                        : "bg-gray-200 text-gray-700"
                    }`}
                  >
                    {tip}
                  </button>
                ))}
              </div>
              {selectedTip === "custom" && (
                <div className="mt-4 flex flex-col items-center">
                  <input
                    type="number"
                    value={customTip.toFixed(2)}
                    onChange={handleCustomTipChange}
                    placeholder="Enter the tip amount"
                    className="p-1 border border-slate-300 dark:border-slate-600 rounded w-full"
                  />
                  {errorMessage && (
                    <p className="text-red-500 mt-2">{errorMessage}</p>
                  )}
                </div>
              )}
            </div>
          )}
          {option === "delivery" && (
            <div className="mt-1 mb-5">
              <p className="text-sm font-semibold text-slate-700 dark:text-slate-200">
                Delivery Tip
              </p>
              <div className="flex space-x-2 mt-2">
                {["none", "10%", "15%", "20%", "custom"].map((tip) => (
                  <button
                    key={tip}
                    type="button"
                    onClick={() => handleDeliveryTipSelection(tip)}
                    className={`px-4 py-2 border rounded ${
                      selectedDeliveryTip === tip
                        ? "bg-black text-white"
                        : "bg-gray-200 text-gray-700"
                    }`}
                  >
                    {tip}
                  </button>
                ))}
              </div>
              {selectedDeliveryTip === "custom" && (
                // <div className="mt-4 flex items-center">
                //   <input
                //     type="number"
                //     value={customTip}
                //     onChange={handleCustomTipChange}
                //     placeholder="enter the tip amount"
                //     className=" p-1 border border-slate-300 dark:border-slate-600 rounded w-full"
                //   />
                // </div>
                <div className="mt-4 flex flex-col items-center">
                  <input
                    type="number"
                    value={customTip.toFixed(2)}
                    onChange={handleCustomTipChange}
                    placeholder="Enter the tip amount"
                    className="p-1 border border-slate-300 dark:border-slate-600 rounded w-full"
                  />
                  {errorMessage && (
                    <p className="text-red-500 mt-2">{errorMessage}</p>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        {NoteContent()}
        <div className="mt-4 flex justify-between py-2.5">
          <span>Subtotal</span>
          <span className="font-semibold text-slate-900 dark:text-slate-200">
            ${subtotal.toFixed(2)}
          </span>
        </div>
        <div>
          {/* {reOrderData ? (
          
            
          ):()} */}
          {taxDisplayList.map((taxDisplay, index) => (
            <div key={index} className="flex justify-between py-2">
              <span>{taxDisplay}</span>
              <span className="font-semibold text-slate-900 dark:text-slate-200">
                ${taxDisplayTotal[index].toFixed(2)}
              </span>
            </div>
          ))}
        </div>
        {option === "pickup" && (
          <div className="mt-1 flex justify-between py-2.5">
            <span>Tip</span>
            {selectedTip ? (
              <span className="font-semibold text-slate-900 dark:text-slate-200">
                ${finalTip(selectedTip).toFixed(2)}
              </span>
            ) : (
              <span className="font-semibold text-slate-900 dark:text-slate-200">
                $0.00
              </span>
            )}
          </div>
        )}
        {option === "delivery" && (
          <>
            <div className="mt-1 flex justify-between py-2.5">
              <span>Delivery Tip</span>
              {selectedDeliveryTip ? (
                <span className="font-semibold text-slate-900 dark:text-slate-200">
                  ${finalDeliveryTip(selectedDeliveryTip).toFixed(2)}
                </span>
              ) : (
                <span className="font-semibold text-slate-900 dark:text-slate-200">
                  $0.00
                </span>
              )}
            </div>
            <div className="mt-1 flex justify-between py-2.5">
              <span>Delivery Fee</span>
              {deliveryFee ? (
                <span className="font-semibold text-slate-900 dark:text-slate-200">
                  ${(Number(deliveryFee) / 100).toFixed(2)}
                </span>
              ) : (
                <span className="font-semibold text-slate-900 dark:text-slate-200">
                  $0.00
                </span>
              )}
            </div>
          </>
        )}
        <div className="mt-1 flex justify-between py-2.5">
          <span>{storedOrderFeeLabel}</span>
          <span className="font-semibold text-slate-900 dark:text-slate-200">
            ${storedOrderFee.toFixed(2)}
          </span>
        </div>
        <div className="flex justify-between font-semibold text-slate-900 dark:text-slate-200 text-base pt-4">
          <span>Order Total</span>
          <span> ${totalAmount}</span>
        </div>
      </div>

      <div className="relative group">
        <ButtonPrimary
          className={`justify-center mt-8 w-full ${
            isPaymentProcessing || deliveryOn || newquote
              ? "disabled-button"
              : ""
          }`}
          disabled={
            isPaymentProcessing ||
            deliveryOn ||
            newquote ||
            (schedulePickup && errorMessages !== "")
          }
        >
          {isPaymentProcessing ? "Payment Processing..." : "Confirm Order"}
        </ButtonPrimary>
        {deliveryOn && newquote && (
          <div className="absolute top-[-40px] left-1/2 transform -translate-x-1/2 bg-white text-red-600 text-sm px-3 py-1 rounded border border-red-600 shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200">
            Please choose the delivery option
          </div>
        )}
      </div>
      <div className="mt-5 text-sm text-slate-500 dark:text-slate-400 flex items-center justify-center"></div>
    </div>
  );
};
export default OrderSummary;
